import React, { useState, useEffect, useContext, createContext } from "react";
import Cookies from 'js-cookie';
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import { publish } from "../services/events";
import { useHistory } from "react-router-dom";
import ObjectID from "bson-objectid";

let localStorageSupported = false;
try {
  localStorageSupported = window.localStorage && true;
} catch (e) {}

const authContext = createContext();
const AUTH_COOKIE_NAME = process.env.REACT_APP_COOKIE_PREFIX + "wiseox_token";
const AUTH_GUEST_COOKIE_NAME = process.env.REACT_APP_COOKIE_PREFIX + "wiseox_guest_token";

function useProvideAuth() {
  // State auth vars
  const [authToken, setAuthToken] = useState((localStorageSupported && localStorage.getItem(AUTH_COOKIE_NAME)) || null);
  const [publicAccess, setPublicAccess] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [guest, setGuest] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [currentOrganization, setCurrentOrganizationData] = useState({});
  const [clientId, setClientId] = useState(localStorage.getItem("clientId"));
  const [uploadInfo, setUploadInfo] = useState({});
  const history = useHistory();

  // Loading vars
  const [loading, setLoading] = useState(!!authToken);
  const [, setSubmitting] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isGuestAuthenticated, setIsGuestAuthenticated] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [signupError, setSignupError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    if (authToken && !isAuthenticated) {
      client.api.setHeaders({ Authorization: `Bearer ${authToken}` });
      servicesClient.api.setHeaders({ Authorization: `Bearer ${authToken}` });
      client.me().then((res) => {
        if (res.ok) {
          if (res.data.success !== false) {
            // Set token and get current org
            localStorageSupported && localStorage.setItem(AUTH_COOKIE_NAME, authToken);
            localStorageSupported && localStorage.setItem("clientId", res.data.user._id);

            let paramOrg = new URLSearchParams(window.location.search).get("selectOrg");
            const currentOrgId = paramOrg || (localStorageSupported && localStorage.getItem("currentOrgId"));
            let currentOrg = res.data.organizations.find((o) => o._id === currentOrgId);

            if (res.data.organizations.length === 0) {
              publish("noOrg");
            }

            // Set auth vars
            setCurrentUser(res.data.user);
            setClientId(res.data.user._id);
            setOrganizations(res.data.organizations);
            setCurrentOrganization(currentOrg || res.data.organizations[0]);
            setIsAuthenticated(true);
            setLoading(false);
          } else {
            logOut();
            setLoading(false);
            history.push(`/login`);
          }
        } else {
          setIsAnonymous(true);
        }
      });
    } else if (!authToken) {
      setIsAnonymous(true);
      if (localStorage.getItem("clientId") === null) {
        localStorage.setItem("clientId", ObjectID().toHexString());
      }
      setClientId(localStorage.getItem("clientId"));
    }
  }, [authToken, isAuthenticated, history]);

  useEffect(() => {
    guestLogIn();
  }, []);

  useEffect(() => {
    servicesClient.uploadInfo().then((result) => {
      if (result.ok) {
        setUploadInfo(result.data);
      }
    });
  }, []);

  const logIn = async (username, password, invite) => {
    setLoading(true);

    try {
      const authResult = await client.logIn({ username, password, invite });

      if (!authResult.ok) {
        throw authResult.data;
      }

      const token = authResult.data.user.token;
      setAuthToken(token);
      localStorageSupported && localStorage.setItem(AUTH_COOKIE_NAME, token);
      localStorageSupported && localStorage.setItem("clientId", authResult.data.user._id);
      client.api.setHeaders({ Authorization: `Bearer ${token}` });
      servicesClient.api.setHeaders({ Authorization: `Bearer ${token}` });

      setCurrentUser(authResult.data.user);
      setClientId(authResult.data.user._id);
      setOrganizations(authResult.data.organizations);

      if (authResult.data.organizations.length === 0) {
        publish("noOrg");
      }

      let paramOrg = new URLSearchParams(window.location.search).get("selectOrg");
      const currentOrgId = paramOrg || (localStorageSupported && localStorage.getItem("currentOrgId"));
      let currentOrg = authResult.data.organizations.find((o) => o._id === currentOrgId);

      setCurrentOrganization(currentOrg || authResult.data.organizations[0]);
      setIsAuthenticated(true);
      setHasError(false);
      setError(null);
      setLoading(false);
    } catch (error) {
      setHasError(true);
      if (error) {
        setError(error.message);
      }
      setLoading(false);
    }
  };

  const guestLogIn = () => {
    const hasGuestCookie = Boolean(Cookies.get(AUTH_GUEST_COOKIE_NAME));
    setIsGuestAuthenticated(hasGuestCookie);
    if (hasGuestCookie) {
      setGuest(JSON.parse(atob(Cookies.get(AUTH_GUEST_COOKIE_NAME))));
    }
  };

  const logOut = () => {
    localStorageSupported && localStorage.removeItem(AUTH_COOKIE_NAME);
    setAuthToken(null);
    setCurrentUser(null);
    setClientId(null);
    setIsAuthenticated(false);
  };

  const signUp = (data) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      organization: data.organization,
      email: data.email,
      password: data.password,
      invitationToken: data.invitationToken,
    };

    return client
      .signUp(payload)
      .then((res) => {
        if (res.data.status === 400) {
          setSignupError("Sorry, you cannot use that email address to sign up.");
          return false;
        } else if (res.data.status === 401) {
          setSignupError("Sorry, you cannot use that organization name.");
          return false;
        } else {
          return true;
        }
      })
      .catch((err) => {
        setHasError(true);
        console.error(err);
      });
  };

  const sendPasswordResetEmail = async (email) => {
    setSubmitting(true);

    try {
      await client.recoverPassword(email);
      setSubmitting(false);
      setHasError(false);
      setError(null);
    } catch (error) {
      setHasError(true);
      setError(error);
      setSubmitting(false);
    }
  };

  const confirmPasswordReset = async ({ token, password }) => {
    return client.resetPassword({ token, password });
  };

  const updateCurrentUser = async (user) => {
    await client.updateUser(user);
    setCurrentUser(user);
  };

  const handleSignupError = (value) => {
    setSignupError(value);
  };

  const handleEmailError = (value) => {
    setEmailError(value);
  };

  const handleError = (value) => {
    setError(value);
  };

  const setCurrentOrganization = (org) => {
    if (org) {
      localStorage.setItem("currentOrgId", org._id);
      setCurrentOrganizationData(org);
    }
  };

  const updateOrganization = (org) => {
    const organizationsNewList = organizations.map((o) => (o._id === org._id ? org : o));
    setOrganizations(organizationsNewList);
    if (currentOrganization._id === org._id) {
      setCurrentOrganizationData(org);
    }
  };

  // Return the user object and auth methods
  return {
    loading,
    isAuthenticated,
    isGuestAuthenticated,
    isAnonymous,
    clientId,
    currentUser,
    updateCurrentUser,
    publicAccess,
    setPublicAccess,
    logIn,
    guestLogIn,
    guest,
    setGuest,
    logOut,
    signUp,
    sendPasswordResetEmail,
    confirmPasswordReset,
    hasError,
    error,
    handleError,
    signupError,
    handleSignupError,
    emailError,
    handleEmailError,
    authToken,
    currentOrganization,
    setCurrentOrganization,
    organizations,
    updateOrganization,
    uploadInfo,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
