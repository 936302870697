import React, { useState, useEffect, useContext, createContext } from "react";
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import { subscribe, unsubscribe } from "../services/events";
import { useMascot } from "./useMascot";
import { useSocket } from "./ws";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
const tasksContext = createContext();

function useProvideTasks() {
  const { pathname } = useLocation();
  const route = matchPath(pathname, { path: "/mascot/:mascotId" });
  let mascotId = route?.params?.mascotId;
  const [taskList, setTaskList] = useState([]);
  const [hasPendingTasks, setHasPendingTasks] = useState(false);
  const [embeddingTask, setEmbeddingTask] = useState();
  const [embeddingProgress, setEmbeddingProgress] = useState({});
  const [embeddingTaskLastResult, setEmbeddingTaskLastResult] = useState();
  const [minimized, setMinimized] = useState(true);
  const { mascot, setMascot } = useMascot();
  const { socket } = useSocket();

  useEffect(() => {
    socket && socket.on("embeddingProgress", onEmbeddingProgress);
    subscribe("generateEmbeddingTask", onGeneratedEmbedding);
    subscribe("newTaskStarted", onNewTask);
    subscribe("onTaskCompleted", onTaskCompleted);
    subscribe("removedDoc", onRemovedDoc);
    return () => {
      socket && socket.off("embeddingProgress", onEmbeddingProgress);
      unsubscribe("generateEmbeddingTask", onGeneratedEmbedding);
      unsubscribe("newTaskStarted", onNewTask);
      unsubscribe("onTaskCompleted", onTaskCompleted);
      unsubscribe("removedDoc", onRemovedDoc);
    };
    // eslint-disable-next-line
  }, [socket, mascotId]);

  useEffect(() => {
    const fetchTasks = async () => {
      setEmbeddingTaskLastResult();
      setEmbeddingTask();
      setHasPendingTasks();

      let taskList = await refreshTaskList();
      if (taskList && taskList.length > 0) {
        setMinimized(false);
      }
    };

    if (mascotId) {
      fetchTasks();
    }
    // eslint-disable-next-line
  }, [mascotId]);

  const onEmbeddingProgress = async (data) => {
    setEmbeddingProgress(data);
  };

  const refreshTaskList = async () => {
    let mascotData = await client.getTasks(mascotId);
    if (mascotData.ok && mascotData.data) {
      return updateTaskList(mascotData.data);
    }
  };

  const updateTaskList = (tasks) => {
    const blockingPublishTasks = [
      "addFileTask",
      "addWebTask",
      "addGoogleDriveTask",
      "addConfluenceTask",
      "addOneDriveTask",
      "addSharepointTask",
      "addSharepointPageTask",
      "addZendeskTask",
      "refreshWebTask",
      "refreshGoogleDriveTask",
      "refreshConfluenceTask",
      "refreshOneDriveTask",
      "refreshSharepointTask",
      "refreshZendeskTask",
    ];
    let hasPendingTask = false;
    let newTaskList = [];
    let embeddingTask = null;
    for (let task of tasks) {
      if (task.type === "addFileTask" && task.meta?.nonTrainingFileType != null) {
        continue; // exclude addFileTasks for non-training files
      }

      if (task.type === "generateEmbeddingTask") {
        embeddingTask = task;
      }

      if (blockingPublishTasks.indexOf(task.type) !== -1) {
        newTaskList.push(task);
        if (task.status === "queued") {
          hasPendingTask = true;
        }
      }
    }

    setEmbeddingTask(embeddingTask);
    setHasPendingTasks(hasPendingTask);
    let list = newTaskList.reverse();
    setTaskList(list);
    return list;
  };

  const addTask = (task) => {
    setTaskList((taskList) => {
      let replacedUpload = false;
      for (let i in taskList) {
        // If task already present
        if (taskList[i].id === task.id) {
          return [...taskList];
        }

        // If task has to replace an upload
        if (
          task.type === "addFileTask" &&
          taskList[i].type === "uploadFileTask" &&
          taskList[i].id === "file_" + task.meta.filename
        ) {
          replacedUpload = true;
          taskList[i] = task;
        }
      }

      // Add new task
      if (!replacedUpload) {
        taskList.unshift(task);
      }

      setHasPendingTasks(true);
      setEmbeddingTaskLastResult(null);
      setMinimized(false);
      return [...taskList];
    });
  };

  useEffect(() => {
    if (mascot && mascot.embeddingProgress) {
      setEmbeddingProgress(mascot.embeddingProgress);
    }
  }, [mascot]);

  const onGeneratedEmbedding = (data) => {
    setMinimized(false);
    setEmbeddingTask();
    setEmbeddingTaskLastResult(data.detail.result || data.detail);
  };

  const onNewTask = () => {
    setMinimized(false);
    setEmbeddingTaskLastResult(null);
  };

  const onTaskCompleted = (mascotEvent) => {
    // setMinimized(false);
    let mascotCompleted = mascotEvent.detail;
    if (mascotCompleted?._id === mascotId) {
      mascotCompleted.tasks && updateTaskList(mascotCompleted.tasks);
      setMascot((mascot) => {
        mascot.data = mascotCompleted.data;
        return { ...mascot };
      });
    }
  };

  const onRemovedDoc = () => {
    setEmbeddingTaskLastResult(null);
  };

  const handleStopTask = async (taskId) => {
    await servicesClient.taskStop(mascotId, taskId);
    if (embeddingTask && taskId === embeddingTask.id) {
      setEmbeddingTask({});
    }
    refreshTaskList();
  };

  const handleDeleteTask = async (taskId) => {
    await servicesClient.taskDelete(mascotId, taskId);
    refreshTaskList();
  };

  const handleFailedUpload = async (file) => {
    for (let i in taskList) {
      if (taskList[i].type === "uploadFileTask" && taskList[i].id === "file_" + file.name) {
        taskList[i].status = "failed";
        taskList[i].meta.error = "Upload Failed";
      }
    }
    setTaskList([...taskList]);
  };

  const isUpdating = (upload) => {
    for (let task of taskList) {
      if (
        upload.type === "gdrive" &&
        task.status === "queued" &&
        upload.googleDriveId === task.meta.googleDriveId &&
        task.type === "refreshGoogleDriveTask"
      )
        return task;
      if (
        upload.type === "confluence" &&
        task.status === "queued" &&
        upload.nodeType + "-" + upload.confluenceId === task.meta.fileId &&
        task.type === "refreshConfluenceTask"
      )
        return task;
      if (
        upload.type === "onedrive" &&
        task.status === "queued" &&
        upload.oneDriveId === task.meta.oneDriveId &&
        task.type === "refreshOneDriveTask"
      )
        return task;
      if (
        upload.type === "sharepoint" &&
        task.status === "queued" &&
        upload.sharepointId === task.meta.sharepointId &&
        task.type === "refreshSharepointTask"
      )
        return task;
      if (
        upload.type === "zendesk" &&
        task.status === "queued" &&
        upload.zendeskId === task.meta.zendeskId &&
        task.type === "refreshZendeskTask"
      )
        return task;
    }
  };

  return {
    taskList,
    hasPendingTasks,
    embeddingTask,
    embeddingTaskLastResult,
    minimized,
    isUpdating,
    setMinimized,
    setEmbeddingTask,
    refreshTaskList,
    addTask,
    handleStopTask,
    handleDeleteTask,
    handleFailedUpload,
    embeddingProgress,
    setEmbeddingProgress,
    setEmbeddingTaskLastResult,
  };
}

export function ProvideTasks({ children }) {
  const tasks = useProvideTasks();
  return <tasksContext.Provider value={tasks}>{children}</tasksContext.Provider>;
}

export const useTasks = () => {
  return useContext(tasksContext);
};
